import { AvailableApps } from "../types";

type ColorBrands = {
  [k in AvailableApps]: {
    header: {
      backgroundColor: string;
      color: string;
      buttonColor: string;
      buttonColorSelected: string;
      subMenuColor: string;
    };
  };
};

export const colorBrands: ColorBrands = {
  autobizOffice: {
    header: {
      backgroundColor: "white",
      color: "black",
      buttonColor: "white",
      buttonColorSelected: "#ccc",
      subMenuColor: "black",
    },
  },
  autobizCarcheck: {
    header: {
      backgroundColor: "#F15A24",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#ff9566",
      subMenuColor: "black",
    },
  },
  autobizMarket: {
    header: {
      backgroundColor: "#39B54A",
      color: "white",
      buttonColor: "#63ce72",
      buttonColorSelected: "#78d485",
      subMenuColor: "black",
    },
  },
  autobizMarket_v2: {
    header: {
      backgroundColor: "#39B54A",
      color: "white",
      buttonColor: "#63ce72",
      buttonColorSelected: "#78d485",
      subMenuColor: "black",
    },
  },
  autobizTrade: {
    header: {
      backgroundColor: "#00693B",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#00ce73",
      subMenuColor: "black",
    },
  },
  autobizAPI: {
    header: {
      backgroundColor: "#009A9A",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#00c8c8",
      subMenuColor: "black",
    },
  },
  autobizAcademy: {
    header: {
      backgroundColor: "#F6A02D",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#f8b761",
      subMenuColor: "black",
    },
  },
  autobizIframe: {
    header: {
      backgroundColor: "#C00F22",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#ee2c41",
      subMenuColor: "black",
    },
  },
  autobizClic2Sell: {
    header: {
      backgroundColor: "#006AB0",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#049bff",
      subMenuColor: "black",
    },
  },
  autobizMonStock: {
    header: {
      backgroundColor: "#602483",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#9136c6",
      subMenuColor: "black",
    },
  },
  autobizConseil: {
    header: {
      backgroundColor: "#009FE3",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#2abfff",
      subMenuColor: "black",
    },
  },
  autobizAi: {
    header: {
      backgroundColor: "#E5005B",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#ff2c7f",
      subMenuColor: "black",
    },
  },
  autobizClic2Buy: {
    header: {
      backgroundColor: "#EA3522",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#F4998F",
      subMenuColor: "black",
    },
  },
  autobizAdsScan: {
    header: {
      backgroundColor: "#04005C",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#807EAC",
      subMenuColor: "black",
    },
  },
  autobizLeads: {
    header: {
      backgroundColor: "#F4974B",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#FFCA7E",
      subMenuColor: "black",
    },
  },
  autobizForecast: {
    header: {
      backgroundColor: "#F4974B",
      color: "white",
      buttonColor: "white",
      buttonColorSelected: "#FFCA7E",
      subMenuColor: "black"
    }
  }
};
